.button {
  padding: 10px;
  background-color: #022f40;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #76B9AF;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.custom-pagination .page-item.active .page-link {
  background-color: #76B9AF;
  border-color: #76B9AF;
  color: white;
}

.input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.status-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 768px) {
  .status-group {
    flex-direction: row;
    align-items: center;
  }

  .status-group label {
    margin-right: 10px;
  }

  .status-group select {
    flex: 1;
  }
}

.settings-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.success-message {
  color: green;
  margin-bottom: 10px;
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 20px;
}

.profile-name {
  display: flex;
  flex-direction: column;
}

.profile-name h3 {
  margin: 0;
  font-size: 24px;
}

.profile-name p {
  margin: 0;
  font-size: 18px;
  color: #666;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-info {
  margin-top: 20px;
}

.contact-info .form-group {
  display: flex;
  align-items: center;
}

.contact-info .form-group label {
  width: 150px;
  margin: 0;
}

.contact-info .form-group input,
.contact-info .form-group p {
  flex: 1;
  margin-left: 20px;
}


/* src/components/WasSchBer.css */

/* Responsive Table Styling */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

@media (max-width: 768px) {
  .table thead {
    display: none;
  }

  .table, .table tbody, .table tr, .table td {
    display: block;
    width: 100%;
  }

  .table tr {
    margin-bottom: 15px;
  }

  .table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
}

.settings-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.settings-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.settings-container h3 {
  margin-top: 30px;
  margin-bottom: 10px;
  color: #555;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 5px;
  color: #666;
  font-weight: 600;
}

.form-group input[type="text"],
.form-group input[type="checkbox"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.form-group input[type="checkbox"] {
  width: auto;
  align-self: flex-start;
}

.payment-method-group {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.payment-method-group label {
  margin-bottom: 5px;
  font-weight: 600;
}

.payment-method-group input[type="text"] {
  margin-bottom: 10px;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .settings-container {
      padding: 15px;
  }

  .form-group {
      margin-bottom: 20px;
  }

  .button {
      width: 100%;
  }
}

.infotext {
  font-size: 12px;
  color: #666;
  text-align: left;
  margin-top: -10px;
}
