body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
}

nav {
  background-color: darkgreen;
  padding: 10px;
  display: flex;
  justify-content: center;
}

nav a, nav button {
  color: white;
  margin: 0 15px;
  text-decoration: none;
  font-size: 18px;
  border: none;
  background: none;
  cursor: pointer;
}

nav a:hover, nav button:hover {
  text-decoration: underline;
}

.notification {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}


.container {
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: darkgreen;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  color: #333;
}

input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

button {
  padding: 10px;
  background-color: darkgreen;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

p {
  text-align: center;
  color: red;
}
